
















  import { Component, Vue } from 'nuxt-property-decorator'
  import { namespace } from 'vuex-class'

  const cart = namespace('cart')
  const layout = namespace('layout')

  @Component({
    components: {
      OneIconLoader: () => import('~/components/molecules/icons/OneIconLoader.vue'),
    },
  })
  export default class CartValueComponent extends Vue {
    @layout.Getter getCurrency: any
    @layout.Getter isGrossSelected: any

    @cart.Getter getCurrentCartId: any
    @cart.Getter getBasicCartById: any
    @cart.Getter getCurrentCart: any
    @cart.Getter getCartItemsCount: any
    @cart.Getter getCartTotalPrice: any

    get itemsInCart(): number {
      if (this.getCurrentCartId) {
        return this.getCartItemsCount(this.getCurrentCartId)
      }
      return 0
    }

    get price(): number {
      return this.getCartTotalPrice(this.getCurrentCartId, this.isGrossSelected)
    }

    get isCartAvailable() {
      return this.$utils.isPurchaseEnabledOrAuthenticated && !this.$auth.passwordChangeIsRequired()
    }
  }
